import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import useSiteMetadata from "../components/site-data";
import logo from "../assets/images/logo.svg";

export default function Career({data: {post}, location}) {
    const {siteUrl} = useSiteMetadata();
    const seo = post.seo;
    const title = seo?.title ? seo.title : post.title;
    const description = seo?.metaDesc ? seo.metaDesc : post.excerpt.replace(/<[^>]*>?/gm, '');
    const ogDescription = seo?.opengraphDescription ? seo.opengraphDescription : description;
    const ogTitle = seo?.opengraphTitle ? seo.opengraphTitle : title;
    const ogType = seo?.opengraphType ? seo.opengraphType : 'website';
    const ogPublished = seo?.opengraphPublishedTime ? seo.opengraphPublishedTime : post.creationDate;
    const ogModified = seo?.opengraphModifiedTime ? seo.opengraphModifiedTime : post.modified;

    const postTitle = post.title ? parse(post.title) : null;
    const postContent = post.content ? parse(post.content) : null;

    const schema = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": siteUrl + location.pathname
        },
        "headline": title,
        "description": description,
        "publisher": {
            "@type": "Organization",
            "name": "Derave Software",
            "logo": {
                "@type": "ImageObject",
                "url": siteUrl + logo
            }
        },
        "datePublished": post.creationDate,
        "dateModified": post.modified
    }

    return (
        <Layout hideNewsletter>
            <SEO
                title={title}
                description={description}
                schema={schema}
                meta={[
                    {
                        name: `description`,
                        content: description
                    },
                    {
                        property: `og:title`,
                        content: ogTitle
                    },
                    {
                        property: `og:description`,
                        content: ogDescription
                    },
                    {
                        property: `og:type`,
                        content: ogType
                    },
                    {
                        property: `article:published_time`,
                        content: ogPublished
                    },
                    {
                        property: `article:modified_time`,
                        content: ogModified
                    },
                    {
                        name: `twitter:card`,
                        content: 'summary'
                    },
                    {
                        name: `twitter:title`,
                        content: ogTitle
                    },
                    {
                        name: `twitter:description`,
                        content: ogDescription
                    }
                ]}
            />

            <article className="single-article single-career" style={{'paddingTop': '20px'}}>
                <header className="container-fluid">
                    <div className="row columns-8">
                        <h1 className="single-article__title">{postTitle}</h1>
                    </div>
                </header>
              <div className="container-fluid">
                <div className="row columns-8">
                  <section id="career-content">
                    <div className="single-article__main">
                      <div className="page__content single-article__content content">
                        {postContent}
                      </div>
                    </div>
                  </section>
                  <div className="float-box">
                    <div className="float-box__title h4">{postTitle}</div>
                    <div className="float-box__place">{post.career_acfs.careerLocation}</div>
                    <div className="float-box__money">{post.career_acfs.salary}</div>
                    <div className="float-box__devider"/>
                    <div className="float-box__text">{parse(post.career_acfs.mainTechnologies)}</div>
                  </div>
                </div>
              </div>

            </article>
        </Layout>
    )
}

export const pageQuery = graphql`
  query CareerById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpCareer(id: { eq: $id }) {
      id
      excerpt
      uri
      content
      title
      career_acfs {
        careerLocation
        mainTechnologies
        salary
      }
      seo {
          title
          metaDesc
          metaKeywords
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          }
      }
    }
`
